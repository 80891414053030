import React, { useState, useContext } from "react";
import styled, { DefaultTheme } from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
} from "@fortawesome/pro-light-svg-icons";

import GlobalContext from "../../context/GlobalContext";
import Offcanvas from "../Offcanvas";
import { Button } from "../Core";
import NestedMenu from "../NestedMenu";
import { device } from "../../utils/breakpoints";
import Logo from "../Logo";
import { menuItems } from "./menuItems";

interface Theme {
  theme: DefaultTheme;
}

interface ThemeStatus extends Theme {
  dark: boolean | 1 | 0;
}

const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1400px;
  }
`;

const SiteHeader = styled.header<ThemeStatus>`
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background-color: #FFF;
  @media ${device.lg} {
    position: fixed !important;
    transition: 0.4s;
    &.scrolling {
      transform: translateY(-100%);
      transition: 0.4s;
    }
    &.reveal-header {
      transform: translateY(0%);
      box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
      z-index: 9999;
      background: ${({ dark, theme }) =>
        dark ? theme.colors.dark : theme.colors.light};
    }
  }
`;

const ToggleButton = styled.button<ThemeStatus>`
  color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade}!important;
  border-color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade}!important;
`;

interface Menu extends ThemeStatus {}

const Menu = styled.ul<Menu>`
  @media ${device.lg} {
    display: flex;
    justify-content: flex-end;
  }
  .dropdown-toggle {
    cursor: pointer;
  }
  > li {
    > .nav-link {
      @media ${device.lg} {
        color: ${({ dark, theme }) =>
          dark ? theme.colors.light : theme.colors.darkShade}!important;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 18px !important;
        padding-bottom: 18px !important;
        padding-left: 18px !important;
        padding-right: 18px !important;
      }
      &:hover {
        color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }
  .nav-item.dropdown {
    @media ${device.lg} {
      z-index: 99;
    }
    &:hover {
      > .menu-dropdown {
        @media ${device.lg} {
          top: 90%;
          opacity: 1;
          pointer-events: visible;
        }
      }
    }
  }
`;

interface MenuDropdown extends ThemeStatus {}

const MenuDropdown = styled.ul<MenuDropdown>`
  list-style: none;
  @media ${device.lg} {
    top: 110%;
    position: absolute;
    min-width: 227px;
    max-width: 260px;
    box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    padding: 15px 0px;
    z-index: 99;
    opacity: 0;
    transition: opacity 0.4s, top 0.4s;
    pointer-events: none;
    left: -90%;
    border-radius: 0 0 10px 10px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    display: block;
    border-top: ${({ theme }) => `3px solid ${theme.colors.secondary}`};
  }
  > .drop-menu-item {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;

    a {
      color: ${({ theme }) => theme.colors.dark};
      transition: all 0.3s ease-out;
      display: flex;
      align-items: center;
      &.dropdown-toggle::after {
        display: inline-block;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.325em solid;
        border-right: 0.325em solid transparent;
        border-bottom: 0;
        border-left: 0.325em solid transparent;
        top: 1px;
        margin-left: auto;
        transform: rotate(-90deg);
        transition: 0.4s;
      }
    }

    &:hover {
      > a {
        color: ${({ theme }) => theme.colors.secondary};
        text-decoration: none;
        &::after {
          transform: rotate(0deg);
        }
      }
    }
    &.dropdown {
      &:hover {
        > .menu-dropdown {
          @media ${device.lg} {
            top: 10px;
            opacity: 1;
            pointer-events: visible;
            transform: translateX(-100%);
          }
        }
      }
      > .menu-dropdown {
        border-top-color: ${({ theme }) => theme.colors.primary};
        @media ${device.lg} {
          top: 10px;
          left: 0%;
          opacity: 0;
          transform: translateX(-110%);
          transition: 0.4s;
          pointer-events: none;
        }
        > .drop-menu-item {
          @media ${device.lg} {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
    }
  }
  &.dropdown-right {
    left: auto;
    right: -90%;
  }
`;

const MegaMenu = styled.div<MenuDropdown>`
  list-style: none;
  @media ${device.lg} {
    top: 110%;
    position: absolute;
    min-width: 50vw;
    max-width: 50vw;
    box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    z-index: 99;
    opacity: 0;
    transition: opacity 0.4s, top 0.4s;
    pointer-events: none;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    display: flex;
  }
  > .menu-columns {
    flex: 1;
    display: flex;
    padding: 20px 10px 15px;

    > .menu-column {
      flex: 1;
      padding: 10px 15px;

      > .menu-item {
        margin-bottom: 20px;
        padding: 10px;
        cursor: pointer;

        > a {
          > .item-content {
            margin: 0;
            font-size: 14px;
            line-height: 24px;
          }
          > .item-image {
            height: 30px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  > .menu-callout {
    flex: 1;
    border-left: ${({ theme }) => `2px solid ${theme.colors.primary}`};
    padding: 15px;

    > .callout-title {
      margin-bottom: 0px;
      font-weight: 500;
    }
    > .callout-content {
      font-size: 16px;
      line-height: 24px;
    }
    > .callout-link {
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }
  @media (min-width: 1400px) {
    min-width: 700px;
    max-width: 700px;
  }
`;

const ColumTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  border-bottom: 1px solid #dfdfd8;
  margin-bottom: 14px;
  padding-bottom: 10px;
`

const FeatureRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FeatureText = styled.span`
  font-size: 14px;
  font-weight: 200;
  margin-left: 10px;
  color: #021d2d;
`;

const Icon = styled(FontAwesomeIcon)`

`

const FeatureItem = styled.div`
  padding: 5px 10px;
  &:hover ${FeatureText} {
    color: ${({ theme }) => theme.colors.primary};
  };
  &:hover ${Icon} {
    color: ${({ theme }) => theme.colors.primary};
  };
`;

const ProductTitle = styled.p`
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
  line-height: 16px;
  color: #021d2d;
`
const ProductText = styled.p`
  font-weight: 200;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 16px;
  color: rgbrgba(25,25,27,0.6);
`

const ProductItem = styled.div`
  padding: 5px 10px 10px;
  &:hover ${ProductTitle} {
    color: ${({ theme }) => theme.colors.primary};
  };
  &:hover ${ProductText} {
    color: ${({ theme }) => theme.colors.primary};
  };
`;

const Callout = styled.div`
  background-color: #f0f9fe;
  padding: 0px;
  flex: 1;
`
const CalloutContainer = styled.div`
  padding: 10px 15px;
`

const  CalloutTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 5px;
`

const  CalloutHeader = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 14px;
  color: #021d2d;
`

const CalloutText = styled.p`
  font-weight: 200;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 16px;
  color: rgb(13, 51, 63);
`

const CalloutLink = styled.span`
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
  line-height: 16px;
  color: #021d2d;
`

const CallOutImage = styled.div`
  width: 100%;
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
`

interface Props {
  isDark: boolean;
}

const Header: React.FC<Props> = ({ isDark = false }) => {
  const gContext = useContext(GlobalContext);
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
  });

  return (
    <>
      <SiteHeader
        className={`sticky-header ${showScrolling ? "scrolling" : ""} ${
          showReveal ? "reveal-header" : ""
        }`}
        dark={0}
      >
        <Container>
          <nav className="navbar site-navbar offcanvas-active navbar-expand-lg navbar-light">
            {/* <!-- Brand Logo--> */}
            <div className="brand-logo">
              <Logo white={isDark ? (showScrolling ? false : true) : isDark} />
            </div>
            <div className="collapse navbar-collapse">
              <div className="navbar-nav ml-lg-auto mr-3">
                <Menu
                  className="navbar-nav d-none d-lg-flex"
                  dark={isDark ? (showScrolling ? 0 : 1) : 0}
                >
                  {menuItems.map(
                    (
                      {
                        label,
                        isExternal = false,
                        name,
                        items,
                        megaMenu,
                        ...rest
                      },
                      index
                    ) => {
                      const hasSubItems = Array.isArray(items);
                      const hasMegaMenu = !!megaMenu;

                      const getItemContent = () => {
                        if (hasSubItems)
                          return (
                            <li className="nav-item dropdown" {...rest}>
                              <a
                                className="nav-link dropdown-toggle"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                href="/#"
                                onClick={(e) => e.preventDefault()}
                              >
                                {label}
                              </a>
                              <MenuDropdown
                                className="menu-dropdown dropdown-right"
                                dark={isDark ? 1 : 0}
                              >
                                {items &&
                                  items.map((subItem, indexSub) => {
                                    const hasInnerSubItems = Array.isArray(
                                      subItem.items
                                    );
                                    return (
                                      <React.Fragment
                                        key={subItem.name + indexSub}
                                      >
                                        {hasInnerSubItems ? (
                                          <li className="drop-menu-item dropdown">
                                            <a
                                              className="dropdown-toggle"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-expanded="false"
                                              href="/#"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              {subItem.label}
                                            </a>
                                            <MenuDropdown
                                              className="menu-dropdown dropdown-right"
                                              dark={isDark ? 1 : 0}
                                            >
                                              {subItem?.items?.map(
                                                (itemInner, indexInnerMost) => (
                                                  <li
                                                    className="drop-menu-item"
                                                    key={
                                                      itemInner.name +
                                                      indexInnerMost
                                                    }
                                                  >
                                                    {itemInner.isExternal ? (
                                                      <a
                                                        href={`${itemInner.name}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {itemInner.label}
                                                      </a>
                                                    ) : (
                                                      <Link
                                                        to={`/${itemInner.name}`}
                                                      >
                                                        {itemInner.label}
                                                      </Link>
                                                    )}
                                                  </li>
                                                )
                                              )}
                                            </MenuDropdown>
                                          </li>
                                        ) : (
                                          <li className="drop-menu-item">
                                            {subItem.isExternal ? (
                                              <a
                                                href={`${subItem.name}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                {subItem.label}
                                              </a>
                                            ) : (
                                              <Link to={`/${subItem.name}`}>
                                                {subItem.label}
                                              </Link>
                                            )}
                                          </li>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                              </MenuDropdown>
                            </li>
                          );

                        if (hasMegaMenu)
                          return (
                            <li
                              className="nav-item dropdown"
                              style={{ position: "initial" }}
                              {...rest}
                            >
                              <a
                                className="nav-link dropdown-toggle"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                href="/#"
                                onClick={(e) => e.preventDefault()}
                              >
                                {label}
                              </a>
                              <MegaMenu
                                className="menu-dropdown dropdown-right"
                                dark={isDark ? 1 : 0}
                              >
                                <div className="menu-columns">
                                  <div
                                    className="menu-column"
                                    key={`column-R${index}`}
                                  >
                                    <ColumTitle>{megaMenu.products.title}</ColumTitle>
                                    {megaMenu.products.items.map(
                                      (item, index) => (
                                        <ProductItem
                                          key={`item-${index}`}
                                        >
                                          <Link to={`/${item.to}`}>
                                            <ProductTitle>
                                              {item.title}
                                            </ProductTitle>
                                            <ProductText>
                                              {item.content}
                                            </ProductText>
                                          </Link>
                                        </ProductItem>
                                      )
                                    )}
                                  </div>
                                  {/*<div*/}
                                  {/*  className="menu-column"*/}
                                  {/*  key={`column-R${index}`}*/}
                                  {/*>*/}
                                  {/*  <ColumTitle>{megaMenu.features.title}</ColumTitle>*/}
                                  {/*  {megaMenu.features.items.map(*/}
                                  {/*    (item, index) => (*/}
                                  {/*      <FeatureItem key={`item-${index}`}>*/}
                                  {/*        <Link to={`/${item.to}`}>*/}
                                  {/*          <FeatureRow>*/}
                                  {/*            <Icon*/}
                                  {/*              height={50}*/}
                                  {/*              icon={item.icon}*/}
                                  {/*              color="#021d2d"*/}
                                  {/*            />*/}
                                  {/*            <FeatureText>*/}
                                  {/*              {item.text}*/}
                                  {/*            </FeatureText>*/}
                                  {/*          </FeatureRow>*/}
                                  {/*        </Link>*/}
                                  {/*      </FeatureItem>*/}
                                  {/*    )*/}
                                  {/*  )}*/}
                                  {/*</div>*/}
                                </div>
                                {/*<Callout>*/}
                                {/*  <CallOutImage style={{ backgroundImage: `url(${megaMenu.callOut.image}` }} />*/}
                                {/*  <CalloutContainer>*/}
                                {/*    <CalloutTitle>*/}
                                {/*      {megaMenu.callOut.title}*/}
                                {/*    </CalloutTitle>*/}
                                {/*    <CalloutHeader>*/}
                                {/*      {megaMenu.callOut.header}*/}
                                {/*    </CalloutHeader>*/}
                                {/*    <CalloutText>*/}
                                {/*      {megaMenu.callOut.content}*/}
                                {/*    </CalloutText>*/}
                                {/*    <Link*/}
                                {/*      to={`/${megaMenu.callOut.to}`}*/}
                                {/*      role="button"*/}
                                {/*      aria-expanded="false"*/}
                                {/*      style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}*/}
                                {/*    >*/}
                                {/*      <CalloutLink>*/}
                                {/*        Read more*/}
                                {/*      </CalloutLink>*/}
                                {/*      <FontAwesomeIcon height={30} icon={faArrowRight} color="#021d2d" />*/}
                                {/*    </Link>*/}
                                {/*  </CalloutContainer>*/}
                                {/*</Callout>*/}
                              </MegaMenu>
                            </li>
                          );

                        return (
                          <li className="nav-item" {...rest}>
                            {isExternal ? (
                              <a
                                className="nav-link"
                                href={`${name}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {label}
                              </a>
                            ) : (
                              <Link
                                className="nav-link"
                                to={`/${name}`}
                                role="button"
                                aria-expanded="false"
                              >
                                {label}
                              </Link>
                            )}
                          </li>
                        );
                      };

                      return (
                        <React.Fragment key={name + index}>
                          {getItemContent()}
                        </React.Fragment>
                      );
                    }
                  )}
                </Menu>
              </div>
            </div>
            <div className="header-btns ml-auto ml-lg-0 d-none d-md-block">
              <a href="https://app.shopsafealert.co.uk">
                <Button
                  size="sm"
                  variant="solid"
                  color="primary"
                  css={`
                    font-size: 16px !important;
                    min-width: 141px !important;
                    height: 45px !important;
                    margin-right: 10px;
                  `}
                >
                  Alert! Login
                </Button>
              </a>
              {/* <Link to="/contact">
              <Button
                size="sm"
                css={`
                  font-size: 16px !important;
                  min-width: 141px !important;
                  height: 45px !important;
                `}
              >
               Contact Us
              </Button>
              </Link> */}
            </div>
            <ToggleButton
              className={`navbar-toggler btn-close-off-canvas ml-3 ${
                gContext.visibleOffCanvas ? "collapsed" : ""
              }`}
              type="button"
              data-toggle="collapse"
              data-target="#mobile-menu"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={gContext.toggleOffCanvas}
              dark={isDark ? 1 : 0}
            >
              {/* <i className="icon icon-simple-remove icon-close"></i> */}
              <i className="icon icon-menu-34 icon-burger d-block"></i>
            </ToggleButton>
          </nav>
        </Container>
      </SiteHeader>
      <Offcanvas
        show={gContext.visibleOffCanvas}
        onHideOffcanvas={gContext.toggleOffCanvas}
      >
        <NestedMenu menuItems={menuItems} />
      </Offcanvas>
    </>
  );
};
export default Header;
