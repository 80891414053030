import React from "react";
import Box from "./Box";
import { BoxProps } from "./Box";

interface Props extends BoxProps {
  className?: string;
  children: React.ReactNode;
}

const Section: React.FC<Props> = (props) => {
  return <Box py={[5, null, "80px", '90px']} {...props} />;
};

export default Section;
