import React from "react";
import styled from "styled-components";
import {
  color,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
  style,
  ResponsiveValue,
} from "styled-system";
import CSS from "csstype";

const textColor = style({
  prop: "textColor",
  cssProperty: "color",
  key: "colors",
});

interface StyledProps extends SpaceProps, TypographyProps, ShadowProps {
  textColor?: ResponsiveValue<CSS.ColorProperty>;
  bg?: string;
}

const Paragraph = styled.p<StyledProps>`
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  /* letter-spacing: -0.66px; */
  line-height: 28px;
  ${color}
  ${textColor};
  ${space};
  ${typography};
  ${shadow};
`;

const ParagraphSmall = styled(Paragraph)`
  font-size: 16px;
  /* letter-spacing: -0.5px; */
  line-height: 28px;
  ${color}
  ${textColor};
  ${space};
  ${typography};
  ${shadow};
`;

interface Props extends StyledProps {
  variant?: unknown;
  [propName: string]: unknown;
}

const Text: React.FC<Props> = ({ variant, ...props }) => {
  let TextRender;

  switch (variant) {
    case "small":
      TextRender = ParagraphSmall;
      break;
    default:
      TextRender = Paragraph;
  }

  return <TextRender color="text" {...props} />;
};

export default Text;
