import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faChartMixed,
  faHome,
  faSignalBars,
  faCogs,
  faRefresh,
  faMoneyBill,
  faMessages,
  faShield,
  faExclamationCircle,
  faShareNodes,
  faVideo,
  faUserShield,
  faPeopleGroup,
  faMousePointer,
  faLocationDot,
  faMegaphone
} from "@fortawesome/pro-light-svg-icons";

interface Items {
  name: string;
  label: string;
  items?: Items[];
  isExternal?: boolean;
}

interface MegaMenu {
  products: {
    title: string;
    items: {
      to: string;
      title: string;
      content: string;
    }[];
  };
  features: {
    title: string;
    items: {
      to: string;
      text: string;
      icon: IconDefinition;
    }[];
  };
  callOut: {
    title: string;
    header: string;
    content: string;
    image: string;
    to: string;
  };
}

export const menuItems: {
  name: string;
  label: string;
  items?: Items[];
  megaMenu?: MegaMenu;
  isExternal?: boolean;
}[] = [
  {
    name: "crime-partnerships",
    label: "BCRPs & BIDs",
    megaMenu: {
      callOut: {
        content:
          "Combine all of our products into the complete crime fighting solution for collaborative communities in town and city centres.",
        image: "/assets/southend-small.jpeg",
        title: "CASE STUDY",
        header: "Smart Radio in Southbank & Waterloo",
        to: "shopwatch-and-townlink",
      },
      products: {
        title: "PRODUCTS WITH YOU IN MIND",
        items: [
          {
            title: "SmartRadio for TownLink",
            content:
              "Get more from your ShopWatch system with next generation features",
            to: "products/smart-radio-for-crime-partnerships",
          },
          {
            title: "Alert Crime Intelligence Tool",
            content: "Powerful intelligence sharing designed for easy of use",
            to: "products/alert-for-crime-partnerships",
          },
          {
            title: "Insight TownLink CRM",
            content: "Manage, understand and engage with your members",
            to: "products/insight-for-crime-partnerships",
          },
          {
            title: "Body Worn Cameras",
            content: "Evidence collection and management for BCRPs",
            to: "products/bwv-for-crime-partnerships",
          },
        ],
      },
      features: {
        title: "FEATURES FOR CRIME PARTNERSHIPS",
        items: [
          {
            to: "features/reports-and-analytics",
            text: "Report & Analytics",
            icon: faChartMixed,
          },
          {
            to: "features/limitless-coverage",
            text: "Limitless Coverage",
            icon: faSignalBars,
          },
          {
            to: "features/cloud-management",
            text: "Cloud Management",
            icon: faCogs,
          },
          {
            to: "features/service-exchange",
            text: "Service Exchange",
            icon: faRefresh,
          },
          {
            to: "features/income-generation",
            text: "Income Generation",
            icon: faMoneyBill,
          },
          {
            to: "features/member-engagement",
            text: "Member Engagement",
            icon: faMegaphone,
          },
          {
            to: "features/instant-messaging",
            text: "Instant Messaging",
            icon: faMessages,
          },
          {
            to: "features/gdpr-compliance",
            text: "GDPR Compliance",
            icon: faShield,
          },
        ],
      },
    },
  },
  {
    name: "retailers-hospitality",
    label: "Retailers & Hospitality",
    megaMenu: {
      callOut: {
        content:
          "Combine all of our products into the complete crime fighting solution for collaborative communities in town and city centres.",
        image: "",
        to: "shopwatch-and-townlink",
        title: "CASE STUDY",
        header: "Smart Radio in Southbank & Waterloo",
      },
      products: {
        title: "PRODUCTS WITH YOU IN MIND",
        items: [
          {
            title: "Alert Crime Intelligence Tool",
            content:
              "The tool you need to tackle crime, loss and ASB in your stores",
            to: "products/alert-for-retail-hospitality",
          },
          {
            title: "Body Worn Cameras",
            content: "Protect your colleagues and customers with connected cameras",
            to: "products/bwv-for-retail-hospitality",
          },
          {
            title: "SmartRadio Communication",
            content: "A smart and robust communication solution for you teams",
            to: "products/smart-radio-for-retail-hospitality",
          },
        ],
      },
      features: {
        title: "FEATURES FOR RETAILERS & HOSPITALITY",
        items: [
          {
            to: "features/incident-reporting",
            text: "Incident Reporting",
            icon: faExclamationCircle,
          },
          {
            to: "features/investigations",
            text: "Investigations",
            icon: faShareNodes,
          },
          {
            to: "features/tackle-crime-groups",
            text: "Tackle Crime Groups",
            icon: faPeopleGroup,
          },
          {
            to: "features/reports-and-analytics",
            text: "Reports & Analytics",
            icon: faChartMixed,
          },
          {
            to: "features/collaboration",
            text: "Collaboration",
            icon: faMessages,
          },
          {
            to: "features/safe-guarding",
            text: "Colleague Safe Guarding",
            icon: faUserShield,
          },
          {
            to: "features/video-streaming",
            text: "Video Streaming",
            icon: faVideo,
          },
          {
            to: "features/gdpr-compliance",
            text: "GDPR Compliance",
            icon: faShield,
          },
        ],
      },
    },
  },
  {
    name: "shopping-centers",
    label: "Shopping Centres",
    megaMenu: {
      callOut: {
        content:
          "Combine all of our products into the complete crime fighting solution for collaborative communities in town and city centres.",
        image: "",
        title: "CASE STUDY",
        header: "Smart Radio in Southbank & Waterloo",
        to: "shopwatch-and-townlink",
      },
      products: {
        title: "PRODUCTS WITH YOU IN MIND",
        items: [
          {
            title: "SmartRadio Communication",
            content:
              "Boost staff efficiency and get more for you communication system",
            to: "products/smart-radio-for-shopping-centres",
          },
          {
            title: "Body Worn Cameras",
            content: "Protect your staff, tenants and customers",
            to: "products/bwv-for-shopping-centres",
          },
          {
            title: "Alert Crime Intelligence Tool",
            content: "Help your tenants manage loss, crime and ASB",
            to: "products/alert-for-shopping-centres",
          },
        ],
      },
      features: {
        title: "FEATURES FOR SHOPPING CENTRES",
        items: [
          {
            to: "features/software-dispatch",
            text: "Software Dispatch",
            icon: faMousePointer,
          },
          {
            to: "features/internal-positioning",
            text: "Internal Positioning",
            icon: faLocationDot,
          },
          {
            to: "features/safe-guarding",
            text: "Staff Safe Guarding",
            icon: faUserShield,
          },
          {
            to: "features/tenant-engagement",
            text: "Tenant Engagement",
            icon: faPeopleGroup,
          },
          {
            to: "features/limitless-coverage",
            text: "Limitless Coverage",
            icon: faSignalBars,
          },
          {
            to: "features/reports-and-analytics",
            text: "Usage Analytics",
            icon: faChartMixed,
          },
          {
            to: "features/video-streaming",
            text: "Video Streaming",
            icon: faVideo,
          },
        ],
      },
    },
  },
  {
    name: "training",
    label: "Training",
  },
  {
    name: "contact/",
    label: "Contact Us",
  },
];
