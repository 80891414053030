import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Row, Col } from "react-bootstrap";
import { Title, Box, Text } from "../Core";
import Logo from "../Logo";
import { Link } from "gatsby";
import Container from "../../components/Layout/Container";

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

interface Theme {
  theme: DefaultTheme;
}

interface UlStyledProps extends Theme {
  color: keyof DefaultTheme["colors"];
}

const UlStyled = styled.ul<UlStyledProps>`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: #fff !important;
    }
  }
`;

interface CopyRightAreaProps extends Theme {
  dark: boolean | 1 | 0;
}

const CopyRightArea = styled.div<CopyRightAreaProps>`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid #FFF`};

  padding: 15px 0;
  p {
    color: #fff;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: #fff;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }
`;

interface Props {
  isDark: boolean;
  children: React.ReactNode;
}

const Footer: React.FC<Props> = ({ isDark = false }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg="#054265">
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className="justify-content-center">
              <Col lg="3" md="12" className="mb-5 text-center">
                <Logo white />
              </Col>

              <Col lg="3" md="4" className="mb-5 text-center">
                <TitleStyled variant="card" textColor="#FFF">
                  Who We Help
                </TitleStyled>
                <UlStyled color={"darkShade"}>
                  <li>
                    <Link to="/bids-and-councils">
                      BIDs, CRPs &amp; Councils
                    </Link>
                  </li>
                  <li>
                    <Link to="/shopping-centres-and-parks">
                      Shopping Centres &amp; Parks
                    </Link>
                  </li>
                  <li>
                    <Link to="retailers-and-hospitality">
                      Retailers &amp; Hospitality
                    </Link>
                  </li>
                </UlStyled>
              </Col>

              <Col lg="3" md="4" className="mb-0 mb-lg-5 text-center">
                <TitleStyled variant="card" textColor="#FFF">
                  How We Help
                </TitleStyled>
                <UlStyled color={"darkShade"}>
                  <li>
                    <Link to="/shopwatch-and-townlink">
                      ShopWatch &amp; TownLink
                    </Link>
                  </li>
                  <li>
                    <Link to="/alert">Alert</Link>
                  </li>
                  <li>
                    <Link to="/smart-radio">Smart Radio</Link>
                  </li>
                  <li>
                    <Link to="/internal-communication">
                      Internal Communication
                    </Link>
                  </li>
                </UlStyled>
              </Col>

              <Col lg="3" md="4" className="mb-0 mb-lg-5 text-center">
                <TitleStyled variant="card" textColor={"dark"}>
                  &nbsp;
                </TitleStyled>
                <UlStyled color={"darkShade"}>
                  <li>
                    <Link to="/training">Training</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <a href="https://app.shopsafealert.co.uk">Alert! Login</a>
                  </li>
                </UlStyled>
              </Col>
            </Row>
          </Box>

          <CopyRightArea dark={0}>
            <Row className="align-content-center">
              <Col md="5" className=" mb-0 mb-md-2 text-md-left text-center">
                <p>&copy; 2023 ShopSafe Ltd, All Rights Reserved</p>
              </Col>

              <Col
                md="4"
                className="d-inline-flex mb-0 mb-md-2 justify-content-md-start justify-content-center"
              >
                <Link to="/privacy-policy">
                  <Text fontSize={12} ml={2}>
                    Privacy Policy
                  </Text>
                </Link>
                <Link to="/cookie-policy">
                  <Text fontSize={12} ml={2}>
                    Cookie Policy
                  </Text>
                </Link>
              </Col>

              <Col
                md="3"
                className="mt-1 mb-0 mb-md-2 text-md-right text-center"
              >
                <ul className="social-icons">
                  <li>
                    <a
                      href="https://twitter.com/ShopSafeCRIME"
                      target="_blank"
                    >
                      <i
                        style={{ color: "#FFF" }}
                        className="icon icon-logo-twitter"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/shopsafe/"
                      target="_blank"
                    >
                      <i
                        style={{ color: "#FFF" }}
                        className="icon icon-logo-linkedin"
                      ></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
